import QueueItem from "../components/QueueItem";
import { tasksFilter, taskMap } from "../helpers/taskHelper";
import ApproveScreen from "../components/common/approval/ApproveScreen";
import UploadScreen from "../components/common/UploadScreen";
import AuditScreen from "../components/document-control/audit/AuditScreen";
import Archieve from "../components/document-control/archieve/Archieve";
import SelectDocumentsScreen from "../components/credit-check/select-documents/SelectDocumentsScreen";
import PendingTask from "../components/common/PendingTask";
import CompletedTask from "../components/common/CompletedTask";
import SelectPostContolScreen from "../components/document-control/post-control/SelectPostControlScreen";
import PostContolScreen from "../components/document-control/post-control/PostControlScreen";
import AssessmentScreen from "../components/customer-declaration/AssessmentScreen";
import FilesTab from "../components/FilesTab";
import AffordabilityAnswers from "../components/affordability/AffordabilityAnswers";
import { Files } from "../components/Files/Index";
import userTaskGroupBy from "../utils/userTaskGroupBy";
import documentGroupBy from "../utils/documentGroupBy";
import CaseSummary from "../components/case-summary/CaseSummary";
import { queueDefinition } from "../helpers/queueDefinitionNO";

const { featureToggles = [] } = window.stacc_env;
const kycEnabled = featureToggles.includes("kyc");
const kycBusinessEnabled = featureToggles.includes("kyc-business");

export default {
  case: {
    caseSummary: {
      component: CaseSummary,
    },
    tabs: [
      {
        id: "files-tab",
        title: "Documents",
        component: FilesTab,
        hide: (flow: any) => !flow.data.storedFiles,
      },
      {
        id: "affordabilityAnswers",
        title: "Affordability",
        component: AffordabilityAnswers,
        hide: (flow: any) => flow.flowDefinitionId !== "credit-check",
      },
      {
        id: "files",
        title: "Files",
        component: Files,
      },
    ],
    tasks: {
      taskLists: [
        {
          id: "some-task-list",
          map: taskMap,
          query:
            "status=completed&status=pending&status=failed&taskCategory=user-task",
          sort: (taskA: any, taskB: any) => {
            if (taskB.status === "pending" && taskA.status === "completed") {
              return 1;
            }
            if (taskB.actionTitle === "Removed") {
              return -1;
            }
            if (
              taskA.actionTitle === "Approve" &&
              taskB.actionTitle !== "Approve"
            ) {
              return -1;
            }

            if (
              taskA.actionTitle === "Start" &&
              taskB.actionTitle !== "Start"
            ) {
              return -1;
            }

            if (
              taskB.permissions.delete === true &&
              taskA.permissions.delete === false
            ) {
              return 1;
            }

            if (
              taskA.variables?.condition?.requiredInternal === true &&
              taskB.variables?.condition?.requiredInternal === true
            ) {
              if (
                taskA.actionTitle === "Waiting on customer" &&
                taskB.actionTitle !== "Waiting on customer"
              ) {
                return 1;
              }
              return -1;
            }

            if (
              taskA.variables?.condition?.requiredInternal === true &&
              taskB.variables?.condition?.requiredInternal === false
            ) {
              return -1;
            }

            return new Date(taskA.updatedAt) < new Date(taskB.updatedAt)
              ? 1
              : 0;
          },
          groupBy: (task: any) => {
            return (
              userTaskGroupBy[task.taskType] ||
              documentGroupBy[task.context?.conditionType] ||
              "caseworker-task"
            );
          },
          filter: tasksFilter,
          completedTask: CompletedTask,
          pendingTask: PendingTask,
        },
      ],
      taskComponents: {
        // Document control
        "approve-signed-document": {
          component: ApproveScreen,
          titleMap: (task: any) => task.context.conditionType,
        },
        "dcse-approve-signed-document": {
          component: ApproveScreen,
          titleMap: (task: any) => task.context.conditionType,
        },
        "upload-signed-document": {
          component: UploadScreen,
          titleMap: (task: any) => task.context.conditionType,
        },
        "dcse-upload-signed-document": {
          component: UploadScreen,
          titleMap: (task: any) => task.context.conditionType,
        },
        "select-post-control": {
          component: SelectPostContolScreen,
        },
        "dcse-decide-followup-needed": {
          component: SelectPostContolScreen,
        },
        "post-control": {
          component: PostContolScreen,
        },
        "dcse-follow-up": {
          component: PostContolScreen,
        },
        "audit-documents": {
          component: AuditScreen,
          customWidth: "90%",
          customHeight: "90%",
        },
        "dcse-audit-documents": {
          component: AuditScreen,
          customWidth: "90%",
          customHeight: "90%",
        },
        "dc-assess-customer-declaration": {
          component: AssessmentScreen,
          customHeight: "80%",
        },
        // Credit check
        "cc-upload-document": {
          component: UploadScreen,
          titleMap: (task: any) => task.context.conditionType,
        },
        "cc-approve-document": {
          component: ApproveScreen,
          titleMap: (task: any) => task.context.conditionType,
        },
        "cc-select-document-requirements": {
          component: SelectDocumentsScreen,
        },
        "archive-document": {
          component: Archieve,
        },
        "dcse-archive-document": {
          component: Archieve,
        },
        // customer-declaration-private
        "cdp-assessment": {
          component: AssessmentScreen,
          customHeight: "80%",
        },
        "cdp-provide-information-with-co-applicant": {
          customHeight: "80%",
        },
        // customer-declaration-business
        "cdb-assessment": {
          component: AssessmentScreen,
          customHeight: "80%",
        },
      },
    },
    businessStatus: {
      map: (flow: any) => {
        if (flow.data && flow.data.businessStatus) {
          return `business-status-${flow.data.businessStatus}`;
        }
        return null;
      },
    },
  },
  queue: {
    queueItem: QueueItem,
  },
  queueDefinition: queueDefinition,
  menu: {
    menuStructure: [
      { name: "my-cases" },
      {
        name: "credit-process",
        elems: [
          "Credit Inbox",
          "In progress",
          "Approved",
          "Failed credit",
          "credit-unread-chat",
          "credit-not-approved-uploads",
        ],
      },
      {
        name: "document-control",
        elems: [
          "Incoming",
          "Inbox",
          "Control",
          "Audit",
          "Select post control",
          "Post control",
          "Archiving",
          "Failed",
          "document-control-unread-chat",
        ],
      },
      {
        name: "payout-check",
        elems: ["Payout control", "Payout done"],
      },
      {
        name: "follow-up",
        elems: ["document-control-completed-unread-chat"],
      },
      ...(kycEnabled || kycBusinessEnabled
        ? [
            {
              name: "customer-control-car",
              elems: [
                "cd-assessment-queue-car",
                "cd-assessment-assigned-queue-car",
                "kyc-failed-car",
              ],
            },
          ]
        : []),
      ...(kycEnabled || kycBusinessEnabled
        ? [
            {
              name: "customer-control-not-car",
              elems: [
                "cd-assessment-queue-not-car",
                "cd-assessment-assigned-queue-not-car",
                "kyc-failed-not-car",
              ],
            },
          ]
        : []),
    ],
  },
};
