import { useEffect, useState } from "react";
import { CenteredSpinner } from "@stacc/flow-ui-components";

import fileService from "../../../services/files";
import ImageViewer from "../../common/documentsViewer/ImageViewer";
import PdfViewer from "../../common/documentsViewer/PdfViewer";
import { DocumentsViewerProps } from "../../../types/audit";

const DocumentsViewer = ({ fileIds, currentFileId }: DocumentsViewerProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const [files, setFiles] =
    useState<({ id: string; type: any; file: any } | null)[]>();

  useEffect(() => {
    setIsLoading(true);

    const getFilePromises = fileIds.map(async (id) => {
      const result = await fileService.getFile(id);
      if (result) {
        return { id, type: result.type, file: result.file };
      }
      return null;
    });

    Promise.all(getFilePromises).then((result) => {
      const fetchedFiles = result.filter((r) => r !== null);
      setFiles(fetchedFiles);
      setIsLoading(false);
    });
  }, []);

  const currentFile =
    files && files.length > 0
      ? files.find((f) => f?.id === currentFileId)
      : null;

  if (isLoading) {
    return <CenteredSpinner />;
  }

  if (!currentFile) {
    return null;
  }

  if (currentFile.type.includes("image")) {
    return <ImageViewer file={currentFile.file} type={currentFile.type} />;
  }

  if (currentFile.type.includes("pdf")) {
    return <PdfViewer file={currentFile.file} fileId={currentFile.id} />;
  }

  return null;
};

export default DocumentsViewer;
