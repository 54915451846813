import styled from "styled-components";
import { FontStyles } from "@stacc/flow-ui-components";
import { NameBaseContextGroupProps } from "../../../types/common/task-context";

const ContextGroupContainer = styled.div`
  padding: 0 0 10px 0;
`;

const Title = styled.h6`
  :first-letter {
    text-transform: capitalize;
  }
`;

const MainContent = styled.p`
  ${FontStyles.LargeStrong};
`;

const NameBasedContextGroup = ({
  title,
  context,
}: NameBaseContextGroupProps) => (
  <ContextGroupContainer>
    <Title>{title}</Title>
    {Object.keys(context)
      .sort((key) => (key === "name" ? -1 : 1))
      .map((key) => {
        const value = context[key];
        if (key === "name") {
          return (
            <div key={key}>
              <MainContent>{value}</MainContent>
            </div>
          );
        }
        return <div key={key}>{value}</div>;
      })}
  </ContextGroupContainer>
);

export default NameBasedContextGroup;
